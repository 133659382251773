import axios from "axios";
import {defineStore} from "pinia";
import TwilioClientObserver from "../Utilities/TwilioClientObserver";
import Utilities from "../Helpers/utilities";
import {useAppState} from "./AppState";
import {useWorkspace} from "./Workspaces";
import Conversation from "./../Utilities/Conversation";
import {useTeam} from "../Components/Custom/Team/Store/Team";
import {useUserStore} from "./User";
import DateUtilities from "../Utilities/DateUtilities";
import {useInvitation} from "./Invitation";
import {useRequest} from "../Components/Chat/Store/Request.js";
 import HttpStatusCodes from "../Constants/http.js";

export const useConversation = defineStore("Conversation", {
    state: () => ({
        is_chat_panel_opened: false,
        list: [],
        handlers: [],
        activeIndex: 0,
        activeHistoryId: null,
        conversation_history: [],
        history: [],
        history_index: -1,
        is_conversation: false,
        dm_profiles_in_progress: [],
        is_twilio_disconnected: false,
        is_end_assignment: false,
        is_invitation_in_progress: false,
        unread_conversations: [],
        conversations_sids_in_loading: new Map(),
        conversations_ids_in_loading: new Map(),
        image_src_array: [],
        is_twilio_reconnecting: false,
        is_initialized: false,
    }),
    getters: {
        active: (state) => state.handlers[state.activeIndex],
        is_trying_to_connect: (state) => state.is_twilio_reconnecting,
        is_not_trying_to_connect: (state) => !state.is_twilio_reconnecting,
        has_twilio_disconnected: (state) => state.is_twilio_disconnected,
    },
    actions: {
        async grabImageURLsFromServer() {
            try {
                const res = await axios.get(
                    `/medias/conversations/${this.active.id}?index=0&batch_size=30&media_type=image`,
                );
                return res.data?.data?.map(({path}) => path)?.reverse();
            } catch {
                return [];
            }
        },

        disconnected() {
            if (this.is_twilio_disconnected) {
                return;
            }

            this.is_twilio_disconnected = true;
            this.is_twilio_reconnecting = true;

            setTimeout(() => {
                this.is_twilio_reconnecting = false;
            }, 60000);
        },
        connected() {
            this.is_twilio_disconnected = false;
        },
        twilioConnectingStarted() {
            this.twilio_connection_started_at = Date.now();
        },
        resetTwilioConnectingStarted() {
            this.twilio_connection_started_at = 0;
        },
        /* async initialize(list) {
             this.list = list
             this.prepareConversations()
         },*/

        prepareConversations() {
            this.list?.forEach((convData, index) => {
                const convHandler = new Conversation(convData, false);
                this.handlers.push(convHandler);
            });

            this.handlers.forEach((conversation) => {
                if (!conversation.is_quick_load) conversation.initialize();
            });
        },
        loadSpaceBoardsAndItemsForConversations() {
            const item_ids = [];
            this.handlers
                // .slice(0, 99)
                .forEach(
                    (conv) => conv.isItem() && item_ids.push(conv.type_id),
                );

            useWorkspace()
                .loadSpaceAndBoardsForItemIds(item_ids)
                .then(() => this.setWorkSpaceBoardDetailsForEachConversation());
        },
        setWorkSpaceBoardDetailsForEachConversation() {
            this.handlers
                .filter((conv) => conv.isItem())
                .forEach((conv) => conv.attachItemsSpaceAndBoard());
        },
        restore(id, is_opened) {
            this.openConversationById(id);

            // if (is_opened == null)
            //     return

            // Utilities()?.toggleChatPopover(is_opened)
        },

        restoreRecentlyOpenedConversations(conversation_histories) {
            this.history = conversation_histories.reverse();
            this.history_index = this.history.length - 1;
        },
        findIndexById(conv_id) {
            return this.handlers?.findIndex((conv) => conv.id == conv_id);
        },
        findById(conv_id) {
            return this.handlers?.find((conv) => conv.id == conv_id);
        },
        findBySId(sid) {
            return this.handlers?.find((conv) => conv.sid == sid);
        },
        getForItemId(item_id) {
            const mappedConvIndex = this.handlers.findIndex(
                (conv) => conv.isItem() && conv.resourceId == item_id,
            );
            return mappedConvIndex >= 0 ? this.handlers[mappedConvIndex] : null;
        },
        updateNameByItem(item) {
            const conv = this.handlers.find(
                (conv) => conv.isItem() && conv.resourceId == item.id,
            );
            if (!conv)
                return console.log("No Conversation found to update name");

            conv.name = item.name;
        },
        updateNameByType(item) {
            const conv = this.handlers.find(
                (conv) => conv.type === item.type && conv.resourceId == item.id,
            );
            if (!conv)
                return console.log("No Conversation found to update name");

            conv.name = item.name;
        },
        updateAssigneeByItem(item_id, assignee_id = null) {
            const conv = this.handlers.find(
                (conv) => conv.isItem() && conv.resourceId == item_id,
            );
            if (!conv)
                return console.log("No Conversation found to update assignee");

            conv.setAssignee(assignee_id);

            if (conv.originalData?.item?.version) {
                conv.originalData.item.version.user_profile_id_assignee =
                    assignee_id;
            }
        },
        updateOriginalDataByItem(item) {
            const conv = this.handlers.find(
                (conv) => conv.isItem() && conv.resourceId == item.id,
            );
            if (!conv)
                return console.log("No Conversation found to update assignee");

            conv.originalData.item = item;
            conv.setAssignee(item?.version?.user_profile_id_assignee);
        },
        updateSpaceAndBoardByItem(item) {
            const conv = this.handlers.find(
                (conv) => conv.isItem() && conv.resourceId == item.id,
            );
            if (!conv)
                return console.log(
                    "No Conversation found to update space and Board",
                );

            const {space, board} = useWorkspace().getSpaceBoardForItem(
                item.id,
            );
            conv.updateSpaceAndBoard(space, board);
            conv.updateKey();
        },

        setSpaceName(space_name) {
            return (this.state.space_name = space_name);
        },
        updateCurrentSpaceWithNew(space) {
            const conversations = this.handlers.filter(
                (conv) => conv.space_id == space.id || (conv.isSpace() && conv.type_id == space.id),
            );

            if (!conversations)
                return console.log(
                    "No Conversation found to update board name",
                );

            conversations.forEach((conv) => (conv.space_name = space.name));
        },
        updateCurrentBoardWithNew(board) {

            const conversations = this.handlers.filter(
                (conv) => conv.board_id == board.id || (conv.isBoard() && conv.type_id == board.id),
            );

            if (!conversations)
                return console.log(
                    "No Conversation found to update board name",
                );

            conversations.forEach((conv) => {
                conv.board_name = board.name;
            });
        },

        updateWorkspaceForBoard(board, workspace) {
            const conversations = this.handlers.filter(
                (conv) => conv.board_id == board.id || (conv.isBoard() && conv.type_id == board.id),
            );
            conversations.forEach((conv) => {
                conv.space_name = workspace.name;
                conv.space_id = workspace.id;
            });
        },

        openDmConversationForUser(user_profile) {
            return this.openDmConversationForProfileId(user_profile.id);
        },
        openDmConversationForProfileId(user_profile_id) {
            const dmUserProfile = useUserStore().profiles.find(
                (profile) => profile.id == user_profile_id,
            );
            const conversation_index = this.handlers?.findIndex(
                (conv) =>
                    conv.isDm() &&
                    conv.getDMParticipant().twilio_identity ==
                    dmUserProfile.twilio_identity,
            );
            if (conversation_index != -1) {
                this.setHistoryTeamPanel();

                this.openConversastionAt(conversation_index);
                return true;
            }
            return false;
        },
        openConversationById(id) {
            if (!id) return console.log("Invalid conversation");
            const conversation_index = this.findIndexById(id);

            if (conversation_index === null || conversation_index == -1)
                return console.log(
                    `Conversation ${id} not found to restore state`,
                );

            this.toggleProductivityPanel(false);
            this.setActiveConversationAtIndex(conversation_index);
        },
        createAndUpdateActiveIndexWith(conv_data) {
            const conversation = this.findById(conv_data.id);
            if (conversation) {
                conversation?.updateParticipants();
                conversation?.fetchTwilioParticipants();
                return;
            }

            if (conv_data.user_profile_id === useUserStore().getCurrentUserProfileId()) {
                return;
            }

            this.loadConversationBySID(conv_data.sid);

            /*const convHandler = new Conversation(conv_data);

            let new_active_index = this.activeIndex;

            if (this.activeIndex < this.handlers.length)
                new_active_index = this.activeIndex + 1;

            useConversation().handlers.splice(0, 0, convHandler);

            this.activeIndex = new_active_index;*/
        },
        async removeAndUpdateActiveIndexById(conv_id) {
            const index = this.findIndexById(conv_id);
            if (index == -1) return;

            let new_active_index = this.activeIndex;

            if (this.activeIndex > 0) new_active_index = this.activeIndex - 1;

            const conversation_to_delete = this.handlers.splice(index, 1)[0];
            this.activeIndex = new_active_index;

            const current_profile_id = useUserStore().getCurrentUserProfileId();
            if (conversation_to_delete && conversation_to_delete.is_item) {
                try {
                    const resource_type = useInvitation().RESOURCE_TYPES[conversation_to_delete.type];
                    const {data} = await axios.get(`invites/${resource_type}/${conversation_to_delete.type_id}`);
                    const profile = data?.data?.user_profiles.find((user_profile) => user_profile.id === current_profile_id);
                    if (profile || profile?.has_parent_access) {
                        return;
                    }

                    useWorkspace().removeWorkPanelItemBasedOnPermission({
                        type: conversation_to_delete.type,
                        id: conversation_to_delete.type_id,
                    }, data?.data?.resource);

                } catch (error) {
                    console.error(error);
                }
            }

        },
        updateState() {
            useAppState().updateConversationId(this.active.id);
        },
        openConversastionAt(index) {
            this.setActiveConversationAtIndex(index);
            this.updateState();
        },
        openPreviousConversation(prev = true) {
            if (this.conversation_history.length <= 1) return;

            const mappedConvIndex = this.conversation_history.findIndex(
                (conv) => conv.historyId === this.activeHistoryId,
            );

            const next_index = prev ? mappedConvIndex - 1 : mappedConvIndex + 1;

            if (
                next_index < 0 ||
                next_index > this.conversation_history.length - 1
            ) {
                return;
            }

            const prevConversation = this.conversation_history[next_index];

            this.openConversationForItem(
                {
                    id: prevConversation.id,
                    name: prevConversation.name,
                    historyId: prevConversation.historyId,
                },
                true,
            );
        },
        async openOrLoadItemConversation(item) {
            const item_conversation = useConversation().getForItemId(
                item.value.id,
            );
            if (item_conversation) {
                await this.openConversationForItem(item);
            } else {
                const convData = {
                    name: item.value.name,
                    type: "item",
                    type_id: item.value.id,
                    participants: ids,
                };
                this.createFakeConversastion(convData);
            }
        },
        async openConversationForItem(item) {
            this.toggleProductivityPanel(false);
            await this.openConversationOfType(
                useInvitation().ACCESS_LEVELS.ITEM,
                item,
            );
        },

        openConversationBasedOnType(data, type) {
            return this.openConversationOfType(type, data);
        },

        async openConversationOfType(type, {id, name} = data, skip = false) {
            const convData = {
                id: null,
                name: name,
                type: type,
                type_id: id,
            };

            const mappedConvIndex = this.handlers.findIndex(
                (conv) => conv.resourceId == id && conv.type == type,
            );

            if (mappedConvIndex >= 0) {
                if (
                    !skip &&
                    (!this.conversation_history.length ||
                        this.conversation_history[
                        this.conversation_history.length - 1
                            ].id != id)
                )
                    this.conversation_history.push({
                        id: id,
                        name: name,
                        type: type,
                    });

                return this.setActiveConversationAtIndex(mappedConvIndex);
            }

            try {
                await axios.post("/conversations/verify", convData);
            } catch (error) {
                if (error.response) {

                    if (error?.response?.status === HttpStatusCodes.FORBIDDEN) {
                        Utilities().showErrorSnackbar(error?.response?.data?.message);
                        return;
                    }

                    const errors = error.response.data?.errors;
                    if (errors?.archived && errors?.messages?.length) {
                        const conv_handler = new Conversation(
                            errors?.conversation,
                            true,
                        );
                        conv_handler.setAsArchivedConversation();
                        this.handlers.unshift(conv_handler);
                        conv_handler.restoreMessages(errors?.messages);
                        this.setActiveConversationAtIndex(0);
                        return;
                    }
                }
            }
            //remove current fake conversation if any added alredy
            const fakeConvIndex = this.handlers.findIndex((conv) =>
                conv.isFake(),
            );
            if (fakeConvIndex >= 0) this.handlers.splice(fakeConvIndex, 1);

            const convHandler = new Conversation(convData, false);
            convHandler.setAsFakeConversation();
            this.handlers.unshift(convHandler);
            this.setActiveConversationAtIndex(0);
            await this.active.loadConversation();
        },

        async sharePaymentLink(item) {
            const {data} = await axios.get(`/invoices?item_id=${item.id}`);
            if (!data || !data.id)
                return alert(
                    "not possible to share a payment link for this item",
                );

            this.active?.sendPaymentLink(data);
        },

        moveConversationToTop(conversation_id) {
            const index = this.handlers.findIndex(
                (conv) => conv.id == conversation_id,
            );

            if (index == -1)
                return console.log("invalid conversation", conversation_id);
            if (index == 0) return;

            const conversation_to_move = this.handlers.splice(index, 1)[0];
            if (!conversation_to_move) return;

            this.handlers.unshift(conversation_to_move);

            if (index == this.activeIndex)
                return this.setActiveConversationAtIndex(0);

            if (index > this.activeIndex)
                return this.setActiveConversationAtIndex(this.activeIndex + 1);
        },

        isActiveConversationFake() {
            const current = this.active;
            return current?.isFake();
        },

        setActiveConversationAtIndex(active_index) {
            this.activeIndex = active_index;
            this.updateConversationHistory();
        },
        isUserInHistory() {
            const history_length = this.history.length;
            return (
                history_length > 1 && this.history_index < history_length - 1
            );
        },
        updateConversationHistory() {
            // if (this.isUserInHistory())
            //     this.history.splice(this.history_index + 1);

            const current_conv = this.handlers[this.activeIndex];

            if (current_conv.isFake()) return;

            this.history = this.history.filter(
                (conv) => conv.id != current_conv.id,
            );

            this.pushHistoryItem(current_conv);

            this.updateState();
        },

        pushHistoryItem(args) {
            this.history.push(args);
            this.history_index = this.history.length - 1;
        },

        setHistoryItemNotEqualToType(args) {
            return (this.history = this.history.filter(
                (conv) => !conv.type || conv.type != args.type,
            ));
        },

        async nextInHistory() {
            const history_length = this.history.length;
            if (this.history_index >= history_length - 1)
                return (this.history_index = history_length - 1);

            const next_conv = this.history[this.history_index + 1];

            if (!next_conv.id && next_conv.type === "team") {
                this.toggleTeamPanel();
                this.history_index += 1;
                return;
            }

            await this.openOrLoadConversationById(next_conv.id, false);
            this.history_index += 1;
        },

        async previousInHistry() {
            if (this.history_index <= 0) return (this.history_index = 0);

            const next_conv = this.history[this.history_index - 1];

            if (!next_conv.id && next_conv.type === "team") {
                this.toggleTeamPanel();
                this.history_index -= 1;
                return;
            }

            await this.openOrLoadConversationById(next_conv.id, false);
            this.history_index -= 1;
        },

        setHistoryTeamPanel() {
            const args = {
                type: "team",
                tab: "all",
            };

            this.setHistoryItemNotEqualToType(args);
            //this.pushHistoryItem(args)
        },

        toggleTeamPanel(val = true, active_conv = {}, tab = "all") {
            const app_state_store = useAppState();
            app_state_store.toggleInviteDrawer(null, active_conv, val);
            app_state_store.toggleNewGroupInvite(false);
            app_state_store.setLockedSelectedVariantTab(null);
            app_state_store.setSelectedVariantTab(tab);
        },

        toggleProductivityPanel(val = true) {
            const app_state_store = useAppState();
            app_state_store.toggleProductivityDrawer(val);
        },

        isPreviousInHistory() {
            return this.history_index > 0;
        },

        isNextInHistory() {
            const length = this.history.length;
            return this.history_index < length - 1;
        },
        async inviteUsers(
            profile_ids,
            assigned_ids,
            active_conv = this.active,
            item = null,
        ) {
            this.is_invitation_in_progress = true;

            let currently_assigned_id = assigned_ids?.assigned_old;
            let new_assigned_id = assigned_ids?.assigned_new;

            if (currently_assigned_id === undefined) {
                currently_assigned_id = useTeam().currently_assigned;
            }

            if (new_assigned_id === undefined) {
                new_assigned_id = useTeam().assigned;
            }

            if (!item) item = this.getItem(active_conv);

            // if (this.isActiveConversationFake())
            //     return await this.createConversationPlaceholder(
            //         profile_ids,
            //         active_conv,
            //         currently_assigned_id,
            //         new_assigned_id,
            //         item,
            //     );

            let result;

            if (this.active?.is_group || this.active?.is_group_one_off) {
                result = await this.inviteAndAssignUsers(
                    profile_ids,
                    active_conv,
                    currently_assigned_id,
                    new_assigned_id,
                    item,
                );
            } else if (active_conv?.type) {
                result = await this.inviteAndAssignUsersWithRole(
                    profile_ids,
                    active_conv,
                    currently_assigned_id,
                    new_assigned_id,
                    item,
                );
            }
            this.is_invitation_in_progress = false;
            this.updateConversationHistory();
            return result;
        },

        getItem(active_conv) {
            const item_category_id =
                active_conv.originalData?.item?.item_category_id;
            const item_id = active_conv.originalData?.item?.id;
            const board = useWorkspace().getBoardByBoardId(
                active_conv.state?.board_id,
            );

            const item_category = board?.item_categories?.find(
                (item) => item.id === item_category_id,
            );
            return item_category?.items?.find((item) => item.id === item_id);
        },

        async createConversationPlaceholder(
            profile_ids,
            active_conv,
            currently_assigned_id,
            new_assigned_id,
            item = null,
            count = 0,
        ) {
            const payload = {
                type: active_conv.type ?? "item",
                type_id: active_conv.type_id,
                name: active_conv.name,
                user_profile_ids: profile_ids,
                assigned_user_profile_id: new_assigned_id,
            };

            try {
                const {data} = await axios.post(
                    "/conversations/placeholder",
                    payload,
                );

                this.toggleProductivityPanel(false);
                active_conv?.setConversationVariables(data);
                active_conv.sendInvitationForProfiles(profile_ids);

                active_conv.sendAssignedMessage(
                    new_assigned_id,
                    currently_assigned_id,
                );

                active_conv.originalData.item.version = data?.item.version;
                useWorkspace().setLastUpdatedItem(data?.item);
                //active_conv?.setConversationVariables(data)
                //active_conv.setAssignedUser(new_assigned_id)
                return data;
            } catch (e) {
                if (count) {
                    Utilities().showErrorSnackbar(
                        "Operation failed. Please refresh and try again.",
                    );
                    return;
                }
                // wait for 2 second
                await new Promise((resolve) => setTimeout(resolve, 2000));
                this.createConversationPlaceholder(
                    profile_ids,
                    active_conv,
                    currently_assigned_id,
                    new_assigned_id,
                    item,
                    1,
                );
            }
        },

        async inviteAndAssignUsers(
            profile_ids,
            active_conv,
            currently_assigned_id,
            new_assigned_id,
            item = null,
            count = 0,
        ) {
            let response,
                message_indexes = [];

            const item_category = active_conv.getItemCategory();
            const new_profile_ids =
                active_conv?.filterNewlyInvitedProfilesFromList(profile_ids);

            response = await Promise.all([
                active_conv.sendInvitationForProfiles(new_profile_ids),
                active_conv.sendAssignedMessage(
                    new_assigned_id,
                    currently_assigned_id,
                ),
            ]);

            message_indexes.push(response[0]);
            message_indexes.push(response[1]?.[0]);
            message_indexes.push(response[1]?.[1]);
            message_indexes.push(response[2]);

            try {
                const {data} = await axios.post(
                    `/conversations/${active_conv.id}/invite`,
                    {
                        invited_user_profile_ids: profile_ids,
                        assigned_user_profile_id:
                            item_category?.automatic_end_assignment
                                ? currently_assigned_id
                                : new_assigned_id,
                    },
                );

                const conversation_data = data;
                if (!conversation_data.participants) {
                    return;
                }

                active_conv?.updateParticipants(conversation_data.participants);
                if (active_conv?.is_item) {
                    useWorkspace().setLastUpdatedItem(data?.item);
                    active_conv.originalData.item.version = data?.item?.version;
                }

                //active_conv.setAssignedUser(new_assigned_id)
                return data;
            } catch (e) {
                if (count) {
                    console.log("Failed to Invite");
                    Utilities().showErrorSnackbar(
                        "Operation failed. Please refresh and try again.",
                    );
                    message_indexes.forEach(
                        (index) =>
                            index &&
                            active_conv.updateMessageStateAsFailedByIndex(
                                index,
                            ),
                    );
                    return;
                }

                // wait for 2 second
                //removed recursive call.
                /*     await new Promise((resolve) => setTimeout(resolve, 2000));
                     this.inviteAndAssignUsers(
                         profile_ids,
                         active_conv,
                         currently_assigned_id,
                         new_assigned_id,
                         item,
                         1,
                     );*/
            }
        },

        async inviteAndAssignUsersWithRole(
            profile_ids,
            active_conv,
            currently_assigned_id,
            new_assigned_id,
            item = null,
            count = 0,
        ) {
            let response,
                message_indexes = [];

            const item_category = active_conv.getItemCategory();

            const {current_participants, new_modified_participants} =
                useInvitation();

            const new_profile_ids = new_modified_participants?.filter(
                (profile_id) => {
                    return !current_participants.includes(profile_id);
                },
            );

            const temp_ids = [];

            const profiles_with_role =
                useInvitation().user_profiles_with_roles?.map(
                    (user_profile) => {
                        if (user_profile?.non_registered) {
                            temp_ids.push(user_profile.id);
                            user_profile.id = 0;
                        }
                        delete user_profile?.access_level;
                        delete user_profile?.is_organization_user;
                        return user_profile;
                    },
                );

            try {
                const resource_type =
                    useInvitation().RESOURCE_TYPES[active_conv?.type];

                const payload = {
                    invited_user_profile_ids: profile_ids?.filter(
                        (profile_id) =>
                            profile_id && !temp_ids.includes(profile_id),
                    ),
                    invited_profiles: profiles_with_role,
                    assigned_user_profile_id:
                        item_category?.automatic_end_assignment
                            ? currently_assigned_id
                            : new_assigned_id,
                };

                if (payload.assigned_user_profile_id === undefined) {
                    payload.assigned_user_profile_id = null;
                }

                const is_new_conversation = !active_conv.id;
                if (is_new_conversation) {
                    active_conv.isRequestToCreateTwilioConversationAlreadyMade = true;
                }

                const {data} = await axios.post(
                    `/invites/${resource_type}/${active_conv.type_id}`,
                    payload,
                );

                const result = data.data;
                const conversation_data = result.conversation;

                if (!conversation_data) {
                    return;
                }

                active_conv?.updateParticipants(conversation_data.participants);

                response = await Promise.all([
                    active_conv.sendInvitationForProfiles(new_profile_ids),
                    active_conv.sendAssignedMessage(
                        new_assigned_id,
                        currently_assigned_id,
                    ),
                ]);

                message_indexes.push(response[0]);
                message_indexes.push(response[1]?.[0]);
                message_indexes.push(response[1]?.[1]);
                message_indexes.push(response[2]);

                if (active_conv?.is_item) {
                    useWorkspace().setLastUpdatedItem(result.conversation.item);
                    active_conv.originalData = result.conversation;
                }

                active_conv.forceUiUpdate();

                if (is_new_conversation) {
                    active_conv.restoreConversationData(conversation_data);
                }
                //active_conv.setAssignedUser(new_assigned_id)
                return data;
            } catch (e) {
                console.trace(e);
                if (count) {
                    console.log("Failed to Invite");
                    Utilities().showErrorSnackbar(
                        "Operation failed. Please refresh and try again.",
                    );
                    message_indexes.forEach(
                        (index) =>
                            index &&
                            active_conv.updateMessageStateAsFailedByIndex(
                                index,
                            ),
                    );
                    return;
                }

                // wait for 2 second
                await new Promise((resolve) => setTimeout(resolve, 2000));
                // this.inviteAndAssignUsers(
                //     profile_ids,
                //     active_conv,
                //     currently_assigned_id,
                //     new_assigned_id,
                //     item,
                //     1
                // );
            }
        },

        async createConversationUserToBoard(user_profile_ids) {
            if (this.isActiveConversationFake()) {
                const data = {
                    type: this.active.type ?? "board",
                    type_id: this.active.type_id,
                    name: this.active.name,
                    user_profile_ids: user_profile_ids,
                };
                return axios
                    .post("/conversations/placeholder", data)
                    .then(({data}) => {
                        this.active?.setConversationVariables(data);
                        return data;
                    });
            }

            return axios
                .post(`/conversations/${this.active.id}/invite`, {
                    user_profile_ids: user_profile_ids,
                })
                .then(({data}) => {
                    const conversationData = data;
                    if (!conversationData.participants) return;

                    this.active?.setConversationVariables(conversationData);
                    return data;
                });
        },

        async createDMWithProfile(user_profile_id) {
            if (!user_profile_id) return console.log("invalid profile");

            const auth_profile = useUserStore().profile;

            if (user_profile_id == auth_profile.id) return;

            if (this.dm_profiles_in_progress.includes(user_profile_id)) return;

            this.dm_profiles_in_progress.push(user_profile_id);

            if (this.openDmConversationForProfileId(user_profile_id))
                return this.removeProfileIdFromDmProgressList(user_profile_id);

            try {
                const {data} = await axios.post(`/conversations/dm-invite`, {
                    user_profile_id: user_profile_id,
                });
                const conversation_data = data;
                if (!conversation_data.participants) return;

                this.setHistoryTeamPanel();
                await new Promise((resolve) => setTimeout(resolve, 1000));
                let conversation_index = this.handlers.findIndex(
                    (conv) => conv.id == conversation_data.id,
                );
                if (conversation_index == -1) {
                    const conv_handler = new Conversation(conversation_data);
                    this.handlers.unshift(conv_handler);
                    conversation_index = 0;
                }
                this.openConversastionAt(conversation_index);
                useAppState()?.toggleInviteDrawer(false);
                Utilities()?.toggleChatPopover(true);
                this.removeProfileIdFromDmProgressList(user_profile_id);

                return this.handlers.find(
                    (conv) => conv.id == conversation_data.id,
                );
            } catch (e) {
                console.log("Creating dm failed.", e);
            }
        },

        removeProfileIdFromDmProgressList(user_profile_id) {
            this.dm_profiles_in_progress = this.dm_profiles_in_progress.filter(
                (id) => id != user_profile_id,
            );
        },

        async createGroup(
            user_profile_ids,
            name,
            type,
            active_conv = this.active,
        ) {
            active_conv.setIsCreatingConversation(true);
            const data = {
                avatar: useTeam().group_image,
                name: name,
                type: type,
                user_profile_ids: user_profile_ids,
            };
            useTeam().group_image = null;
            return axios
                .post(`/conversations/create-group`, data)
                .then(({data}) => {
                    const conversationData = data;
                    if (!conversationData.participants) return;

                    this.setHistoryTeamPanel();

                    active_conv?.setConversationVariables(data);
                    active_conv.sendGroupCreationInvitaitonForProfiles(
                        user_profile_ids,
                        type,
                    );
                    active_conv.setIsCreatingConversation(false);

                    return data;
                })
                .catch(() => active_conv.setIsCreatingConversation(false));
        },

        getFakeIndex() {
            return this.handlers.findIndex((conv) => conv.isFake());
        },
        createFakeConversastion(convData) {
            const fakeConvIndex = this.getFakeIndex();
            if (fakeConvIndex >= 0) {
                this.active?.setConversationVariables(convData);
                this.setActiveConversationAtIndex(fakeConvIndex);
                return;
            }

            const convHandler = new Conversation(convData, false);
            convHandler.setAsFakeConversation();
            this.handlers.unshift(convHandler);
            this.setActiveConversationAtIndex(0);
        },

        getConversationByParticipantId(id) {
            return this.handlers.filter((conv) =>
                conv.participants.find((user) => user.id === id),
            );
        },

        getConversationBySpaceId(id) {
            return this.handlers.filter((item) => item.space_id === id);
        },

        getConversationByBoardId(id) {
            return this.handlers.filter((item) => item.board_id === id);
        },

        getConversationByItemId(id) {
            return this.handlers.find((item) => item.itemId === id);
        },

        getConversationById(id) {
            return this.handlers.find((item) => item.id === id);
        },

        getSpaceWithBoards(days = null) {
            const spaces = new Map();
            let space, board;

            this.handlers.forEach((conv) => {
                if (!conv.isItem()) return;

                if (days) {
                    if (!this.compareLastMessageDateToNumberOfDays(conv, days))
                        return;
                }

                const space_id = conv.space_id;
                const board_id = conv.board_id;
                space = spaces.get(space_id);

                if (!space)
                    spaces.set(
                        space_id,
                        (space = {
                            id: space_id,
                            name: conv.space_name,
                            boards: new Map(),
                        }),
                    );

                board = space.boards.get(board_id);

                if (!board)
                    space.boards.set(
                        board_id,
                        (board = {
                            id: board_id,
                            name: conv.board_name,
                            unread_messages_count: 0,
                        }),
                    );

                board.unread_messages_count += conv.unreadMessagesCount ?? 0;
            });

            const final_data = Array.from(spaces.values());
            final_data.forEach((space) => {
                space.boards = Array.from(space.boards.values());
            });

            return final_data;
        },

        compareLastMessageDateToNumberOfDays(conv, days) {
            const date_created =
                conv?.getTwilioConversation()?.lastMessage?.dateCreated;
            let dateDifference = 0;

            if (date_created)
                dateDifference = DateUtilities.getDifferenceInDays(
                    date_created,
                    new Date(),
                );

            if (days >= dateDifference) return true;

            return false;
        },

        getBoardsBySpaceId(space_id) {
            return this.handlers.filter(
                (convo, index) =>
                    this.handlers.findIndex(
                        (item) =>
                            item.board &&
                            item.board?.id === convo.board?.id &&
                            item.space?.id === space_id,
                    ) === index,
            );
        },

        getAllUnreadCountByBoardId(board_id, space_id) {
            let total_unread = 0;

            this.handlers.forEach((item) => {
                if (
                    item.board &&
                    item.space &&
                    item.board.id === board_id &&
                    item.space.id === space_id
                )
                    total_unread += item.unreadMessagesCount;
            });

            return total_unread;
        },
        setDocumentByConversationId(conv_id) {
            const conv = this.findById(conv_id);

            if (!conv?.id) return;

            if (!conv?.document) conv.document = {};

            const doc_type = conv.is_item ? conv.type : "conversation";
            const doc_id = conv.is_item ? conv.type_id : conv.id;

            const conv_document = {
                id: conv.document.id ?? null,
                documentable_type: doc_type,
                documentable_id: doc_id,
                content: conv.document.content ?? "",
                title: conv.document.title ?? conv.name,
            };
            conv.document = conv_document;
            return conv;
        },

        prepareDocuementForConversation(conv_id) {
            const conv = this.findById(conv_id);

            if (!conv?.id) return;

            let doc_type = "conversation",
                doc_id = conv.id;

            if (conv.is_item) {
                doc_type = conv.type;
                doc_id = conv.type_id;
            }

            return {
                id: null,
                documentable_type: doc_type,
                documentable_id: doc_id,
                content: "",
                title: conv.name,
            };
        },

        updateConnectionStatusIfNotConnected() {
            const {isTwilioConnected} = TwilioClientObserver();
            if (isTwilioConnected()) return;
            // setTimeout(() => this.disconnected(), 2000);
        },

        setIsEndAssignment(val) {
            this.is_end_assignment = val;
        },

        addUnreadConversation(unread_messages_count, data) {
            const index = this.unread_conversations.findIndex(
                (item) => item?.id === data.id,
            );
            let log_type = null;
            if (unread_messages_count) {
                if (index >= 0) {
                    this.unread_conversations.splice(index, 1, data);
                } else {
                    this.unread_conversations.push(data);
                }
                log_type = "add";
            } else if (index >= 0) {
                this.unread_conversations.splice(index, 1);
                log_type = "remove";
            }

            if (this.unread_conversations.length === 0)
                document.title = `Kaamfu`;
            else
                document.title = `(${this.unread_conversations.length}) Kaamfu`;

            if (log_type) {
                axios.post(`/conversations/${data.id}/log`, {
                    type: log_type,
                    sender_id: data?.author,
                    queue_count: this.unread_conversations.length,
                    message_type: data.type,
                });
            }
        },
        setKaamfuRequestToConversation(conv_data, attr) {
            const req = Array.isArray(attr.kaamfu_request)
                ? undefined
                : [attr.kaamfu_request];

            useRequest().setUrgentRequest(
                useRequest().urgent_request.concat(req),
            );
        },
        loadConversationKaamfuRequests(cnvs) {
            useRequest().setUrgentRequest(cnvs);
        },

        async initialize(conversations, is_reinitialize = false) {
            try {
                this.list = conversations;
                const new_conversation_ids = this.list.map(
                    (conversation) => conversation.id,
                );
                const old_conversation_ids = this.handlers.map(
                    (conversation) => conversation.state.id,
                );
                const conversations_to_add = new_conversation_ids.filter(
                    (conversation_id) =>
                        !old_conversation_ids.includes(conversation_id),
                );
                const conversations_to_delete = old_conversation_ids.filter(
                    (conversation_id) =>
                        !new_conversation_ids.includes(conversation_id),
                );

                for (const conversation_id of conversations_to_delete) {
                    let conversation_index = this.handlers.findIndex(
                        (conversation) =>
                            conversation.state.id === conversation_id,
                    );
                    if (conversation_index !== -1) {
                        this.handlers.splice(conversation_index, 1);
                        console.log(
                            "remove activity at index",
                            conversation_index,
                        );
                    }
                }

                //let index = 0;
                //let limit = 30;
                for (const conversation_id of conversations_to_add) {
                    //index++;
                    let conversation = this.list.find(
                        (list) => list.id === conversation_id,
                    );
                    if (conversation) {
                        if (is_reinitialize) {
                            this.handlers.splice(
                                0,
                                0,
                                new Conversation(conversation),
                            );
                        } else {
                            this.handlers.push(
                                new Conversation({
                                    ...conversation,
                                    is_paginated: true,
                                }),
                            );
                        }
                    }

                    /* if (index % limit === 0) {
                        await new Promise((resolve) =>
                            setTimeout(resolve, 3000),
                        );
                    } */
                }

                if (is_reinitialize) {
                    this.handlers.forEach((current_conversation) => {
                        const new_conversation = this.list.find(
                            (list) => list.id === current_conversation.state.id,
                        );
                        if (new_conversation) {
                            current_conversation.setConversationVariables(
                                new_conversation,
                            );
                            current_conversation.fetchTwilioParticipants();
                        }
                    });
                }
            } catch (e) {
                console.error("reInitializeConversation failed", e);
            }

            this.is_initialized = true;
            if (conversations.length === 0) {
                useAppState().conv_loader = false;
            }
        },
        async archiveConversationById(conversation_id) {
            try {
                await axios.post(`conversations/${conversation_id}/archive`);
                const conversation = this.findById(conversation_id);
                if (conversation) {
                    conversation.setAsArchivedConversation();
                    conversation.setTwilioConversation(null);
                    conversation.removeAllParticipants();
                }
            } catch (e) {
                console.log(
                    "failed to archive conversation " + conversation_id,
                );
            }
        },

        async loadConversationBySID(sid) {
            try {
                const conversation = this.findBySId(sid);
                if (conversation) {
                    return;
                }

                //console.trace("loadConversationBySID", sid);

                if (this.conversations_sids_in_loading.has(sid)) {
                    return;
                }
                this.conversations_sids_in_loading.set(sid, true);

                const {data} = await axios.get(`/conversations/${sid}/sid`);
                const conv_handler = new Conversation(data, true);

                this.handlers.unshift(conv_handler);
                //remember don't change the current active converation as it may distrub users activity
                this.activeIndex += 1;
                this.removeConversationSidFromLoading(sid);
            } catch (error) {
                console.log(
                    "error while fetching conversation by SID:",
                    error.response,
                );
                this.removeConversationSidFromLoading(sid);
                throw error;
            }
        },

        removeConversationSidFromLoading(sid) {
            this.conversations_sids_in_loading.delete(sid);
        },

        removeConversationIdFromLoading(id) {
            this.conversations_ids_in_loading.delete(id);
        },

        async loadConversationById(id) {
            try {
                if (this.conversations_ids_in_loading.has(id)) {
                    return;
                }
                this.conversations_ids_in_loading.set(id, true);
                const {data} = await axios.get(`/conversations/${id}`);
                if (!data) {
                    return;
                }
                const conv_handler = new Conversation(data, true);

                this.handlers.unshift(conv_handler);
                //remember don't change the current active converation as it may distrub users activity
                this.activeIndex += 1;
                this.removeConversationIdFromLoading(id);
            } catch (error) {
                console.log(
                    "error while fetching conversation by SID:",
                    error.response,
                );
                this.removeConversationIdFromLoading(id);
                throw error;
            }
        },

        async openOrLoadConversationById(id, history_update = true) {
            if (!id) return console.log("Invalid conversation");
            let conversation_index = this.findIndexById(id);

            if (conversation_index === null || conversation_index === -1) {
                await this.loadConversationById(id);
                conversation_index = this.findIndexById(id);
                if (conversation_index === null || conversation_index === -1) {
                    return;
                }
            }

            // the code will only execute if conversation_index exist.
            this.toggleProductivityPanel(false);
            this.toggleTeamPanel(false);
            this.activeIndex = conversation_index;

            if (history_update) {
                this.updateConversationHistory();
            } else {
                this.updateState();
            }
        },
    },
});
